import { theme } from '@/src/stitches.config'
import HeadlineEyebrow from '@/src/common/components/marketing/headlines/HeadlineEyebrow'
import {
    LoginProvider,
    useLoginContext,
} from '@/src/modules/login/LoginContext'
import { useEffect } from 'react'
import {
    EMAIL_TOGGLE,
    EXISTING_LOGIN,
} from '@/src/modules/login/LoginConstants'
import { useUser } from '@/src/modules/auth/context/AuthProvider'
import { useRouter } from 'next/router'
import { ClipLoader } from 'react-spinners'
import {
    ACCOUNT_PAGE,
    GIFTS_RECEIVED_PAGE,
    ORDERS_PAGE,
    REFERRALS_PAGE,
    SMB_APPLICATION_PAGE,
} from '@/src/modules/account/received/constants'
import CommonImageAnimation from './imageAnimation'
import {
    CHECKOUT_PATH,
    PARTNER_BRAND_PAGE,
    PARTNER_CHECKOUT_PAGE,
    PARTNER_PATH,
} from '@/src/modules/pg-plus/constants'
import { LoadingWrapper } from '@/src/modules/pg-plus/PGPlusStyledComponents'
import { StyledH1 } from '@/src/common/components/elements/StyledComponents'
import LoginFormWithCustomLeftContent from '@/src/common/components/elements/Forms/LoginFormWithCustomLeftContent'
import toggleChatBot from '@/src/common/utilities/toggleChatBot'
import { TLoginProps } from '@/src/modules/login/types'
import { TPartnerPayload } from '@/src/modules/partners/types'
import { TWhiteLabelProgramFrontEnd } from '@/src/modules/whiteLabelProgram/types'

function determineFlowPath(
    sentFrom: string,
    requiresSendToKyc: boolean,
    partnerData?: TPartnerPayload,
    whiteLabelProgram?: TWhiteLabelProgramFrontEnd
): string {
    if (partnerData) {
        if (sentFrom === 'checkout') {
            return PARTNER_CHECKOUT_PAGE
        }

        const merchantSlug: string = partnerData?.store_page_brand_slug ?? ''

        return merchantSlug
            ? `${PARTNER_PATH}/${merchantSlug}`
            : PARTNER_BRAND_PAGE
    }

    // if from non-partner brands page, send back
    const isFromBrandsPage: boolean = sentFrom?.includes('brands/')

    if (isFromBrandsPage) {
        return `/${sentFrom}`
    }

    if (requiresSendToKyc) {
        return `${CHECKOUT_PATH}?kyc=1`
    }

    switch (sentFrom) {
        case 'orders':
            return `${ORDERS_PAGE}`
        case 'checkout':
            return `${CHECKOUT_PATH}`
        case 'gifts-received':
            return `${GIFTS_RECEIVED_PAGE}`
        case 'referrals':
            return `${REFERRALS_PAGE}`
        case 'smb-application':
            return `${SMB_APPLICATION_PAGE}`
        default:
            return `${ACCOUNT_PAGE}`
    }
}

function redirect(
    router: any,
    sentFrom: string,
    isAdaChat: boolean,
    partnerData?: TPartnerPayload,
    whiteLabelProgram?: TWhiteLabelProgramFrontEnd
) {
    const requiresSendToKyc: boolean =
        !!router?.query?.kyc || router?.query?.overLimits

    const path = determineFlowPath(
        sentFrom,
        requiresSendToKyc,
        partnerData,
        whiteLabelProgram
    )

    // check if the ada chatbot is already open
    // if it is, do not toggle
    const isChatOpen = document
        .getElementById('ada-chat-frame')
        ?.getAttribute('aria-expanded')

    router.replace(path).then(() => {
        if (isAdaChat && !isChatOpen) {
            toggleChatBot()
        }
    })
}

const LoginPage = ({ partnerData, whiteLabelProgram }: TLoginProps) => {
    const {
        loginType,
        emailPhoneToggle,
        actionText,
        setPhoneVerificationText,
        sentFrom,
        isAdaChat,
    } = useLoginContext()

    const { isLoggedIn, isLoaded, isVerified } = useUser()
    const router = useRouter()

    // handles where the user returns to after login / sign up
    useEffect(() => {
        if (isLoggedIn && isVerified) {
            redirect(
                router,
                sentFrom,
                isAdaChat,
                partnerData,
                whiteLabelProgram
            )
        }
    }, [isAdaChat, isLoggedIn, isVerified, partnerData, router, sentFrom])

    // CTA button text when entering phone verification code
    // needs to be different here in the login page
    useEffect(() => {
        setPhoneVerificationText(actionText)
    }, [actionText, setPhoneVerificationText])

    if (!isLoaded) {
        return (
            <LoadingWrapper>
                <ClipLoader size={50} data-testid={'loader'} />
            </LoadingWrapper>
        )
    }

    return (
        <LoginFormWithCustomLeftContent showCorporateLogin={!partnerData}>
            <HeadlineEyebrow
                label={
                    loginType === EXISTING_LOGIN
                        ? 'Welcome Back!'
                        : 'Get Started'
                }
                style={{ marginTop: 15 }}
            />
            <StyledH1 size={'smaller'}>
                {whiteLabelProgram?.program_config
                    ?.login_page_title_before_grad ?? 'A '}
                {whiteLabelProgram?.program_config?.login_page_title_grad ? (
                    <span className={'gradient'}>
                        {
                            whiteLabelProgram?.program_config
                                ?.login_page_title_grad
                        }
                    </span>
                ) : (
                    <span className={'gradient'}>Perfect Gift</span>
                )}
                {whiteLabelProgram?.program_config
                    ?.login_page_title_after_grad ?? (
                    <>
                        <br />
                        Every. Single. Time.
                    </>
                )}
            </StyledH1>
            {whiteLabelProgram?.program_config?.login_page_text ? (
                <p>{whiteLabelProgram?.program_config?.login_page_text}</p>
            ) : (
                <>
                    <p
                        style={{
                            fontSize: '24px',
                            fontWeight: '400',
                            color: `${theme.colors.dark}`,
                        }}
                    >
                        &quot;Such a quick and convenient way to send a gift
                        that gives the recipient options on how they prefer to
                        use it. Love that I can add a photo to it!&quot;
                    </p>
                    <p
                        style={{
                            fontSize: '18px',
                            fontWeight: '700',
                            color: `${theme.colors.darkest}`,
                        }}
                    >
                        -PerfectGift.com Customer
                    </p>
                </>
            )}

            <br />
            <CommonImageAnimation
                color={whiteLabelProgram?.program_config?.primary_color}
                scene={emailPhoneToggle === EMAIL_TOGGLE ? 1 : 2}
            />
        </LoginFormWithCustomLeftContent>
    )
}

export default function CommonMainLoginPage({
    partnerData,
    whiteLabelProgram,
}: Readonly<TLoginProps>) {
    return (
        <LoginProvider>
            <LoginPage
                partnerData={partnerData}
                whiteLabelProgram={whiteLabelProgram}
            />
        </LoginProvider>
    )
}

export async function getCommonLoginIndexServerSideProps() {
    return {
        props: {
            content: {
                data: {
                    title: 'Log In to Your PerfectGift Account',
                    description:
                        'Member log in for PerfectGift.com. Manage your account, view your received gifts, and experience all PerfectGift.com has to offer.',
                },
            },
        },
    }
}
